// Extra Small devices
@mixin media-xs-down {
  @media (max-width: $screen-xs - 1) {
    @content;
  }
}

// Small devices
@mixin media-sm-down {
  @media (max-width: $screen-sm - 1) {
    @content;
  }
}

//// Medium devices
@mixin media-md-down {
  @media (max-width: $screen-md - 1) {
    @content;
  }
}

// Large devices
@mixin media-lg-down {
  @media (max-width: $screen-lg - 1) {
    @content;
  }
}

// Extra large devices
@mixin media-xl-down {
  @media (max-width: $screen-xl - 1) {
    @content;
  }
}

@mixin margin-padding-b8($size) {
  @for $i from 1 through 20 {
    .pt-#{$size}8-#{$i} {
      padding-top: calc(8px * $i) !important;
    }

    .pr-#{$size}8-#{$i} {
      padding-right: calc(8px * $i) !important;
    }

    .pb-#{$size}8-#{$i} {
      padding-bottom: calc(8px * $i) !important;
    }

    .pl-#{$size}8-#{$i} {
      padding-left: calc(8px * $i) !important;
    }

    .mt-#{$size}8-#{$i} {
      margin-top: calc(8px * $i) !important;
    }

    .mr-#{$size}8-#{$i} {
      margin-right: calc(8px * $i) !important;
    }

    .mb-#{$size}8-#{$i} {
      margin-bottom: calc(8px * $i) !important;
    }

    .ml-#{$size}8-#{$i} {
      margin-left: calc(8px * $i) !important;
    }
  }
}
