.container {
  padding: 0;
  overflow: hidden;
}

.bgImage {
  @import '../styles/theme/main';

  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  filter: blur(10px);
  object-fit: cover;
  object-position: top left;

  @include media-sm-down {
    display: none;
  }
}

.main {
  @import '../styles/theme/main';

  height: 100vh; /* fallback for older browsers */
  padding: 0.5rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  height: 100svh; /* Modern browsers */

  @include media-sm-down {
    padding: 0;
  }

  @supports (-webkit-touch-callout: none) and (not (height: 100svh)) {
    .container {
      height: -webkit-fill-available;
      height: stretch;
    }
  }
}
