.bg-primary {
  background: var(--color-primary);
}

.bg-primary--accent {
  background: var(--color-primary-accent) !important;
}

.bg-secondary {
  background: var(--color-secondary-jungleblack);
}

.bg-secondary-light {
  background: var(--color-secondary-lightgray);
}

.color-primary {
  color: var(--color-primary);
}

.color-gray-primary {
  color: var(--color-secondary-jungleblack);
}

.color-charcoal { color: var(--color-secondary-charcoal) }

.color-darkgray { color: var(--color-secondary-darkgray) }

.color-tertiary {
  color: var(--color-tertiary-blue-light);
}

.color-white {
  color: #fff;
}

.d-block {
  display: block;
}

.flex-justify-between {
  justify-content: space-between;
}

.flex-align-center {
  align-items: center;
}

.d-flex {
  display: flex;
}

.d-flex-col {
  display: flex;
  flex-direction: column;
}

.flex-gap-16 { gap: var(--space-16) }

.row-gap-4 { row-gap: var(--space-4) }

.row-gap-8 { row-gap: var(--space-8) }

.row-gap-12 { row-gap: var(--space-12) }

.row-gap-16 { row-gap: var(--space-16) }

.row-gap-20 { row-gap: var(--space-20) }

.row-gap-28 { row-gap: var(--space-28) }

.ft-size-12 { font-size: var(--font-size-12) }

.ft-size-14 { font-size: var(--font-size-14) }

.ft-size-16 { font-size: var(--font-size-16) }

.ft-size-18 { font-size: var(--font-size-18) }

.txt-center {
  text-align: center;
}

.txt-left {
  text-align: left;
}

.no-mg {
  margin: 0;
}

.m-lr-auto {
  margin-left: auto;
  margin-right: auto;
}

.lh-28 {
  line-height: 28px;
}

.mb-12 { margin-bottom: var(--space-12) }
.mt-12 { margin-top: var(--space-12) }
.mt-20 { margin-top: var(--space-20) }
.mt-32 { margin-top: var(--space-32) }

@include media-md-down {
  .txt-center-md { text-align: center }
}

@include media-sm-down {
  @include margin-padding-b8('sm')
}



