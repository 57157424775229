.MuiLinearProgress-root {
  background-color: #f2f2f2;
}

.MuiLinearProgress-bar1Determinate {
  background-color: var(--color-primary);
}

/* Card */
.MuiCard-root {
  cursor: pointer;

  &:hover {
    background: var(--color-primary-alpha);
  }

  .MuiCardContent-root {
    font-size: var(--card-content-font-size, var(--font-size-16));
    padding: var(--card-content-padding, var(--space-16));

    &:last-child {
      padding-bottom: var(--card-content-pb, var(--space-16));
    }
  }
}

/* Form Input */
.MuiFormControl-root {
  .MuiInput-root {
    .MuiInput-input {
      padding: 0;
    }

    &::before {
      border: none;
    }

    &:hover {
      &::before {
        border: none;
      }
    }

    &.Mui-focused {
      &:after {
        transform: scale(0);
      }
    }
  }

  .MuiInput-root.Mui-error {
    &:after {
      transform: scale(0);
    }
  }


  .MuiInputLabel-standard.Mui-focused, .MuiInputLabel-standard.MuiInputLabel-shrink {
    transform: translate(0, 0) scale(0.75);
  }
}

.MuiTabs-root {
  .MuiTabs-indicator {
    background: var(--color-secondary-charcoal);
  }

  .MuiButtonBase-root {
    color: var(--color-secondary-charcoal-alpha);
    font-size: 12px;

    &.Mui-selected {
      color: var(--color-secondary-charcoal);
    }
  }
}

